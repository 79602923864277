import { render, staticRenderFns } from "./WorkbookTableNew.vue?vue&type=template&id=8f5e9cb6&scoped=true"
import script from "./WorkbookTableNew.vue?vue&type=script&lang=js"
export * from "./WorkbookTableNew.vue?vue&type=script&lang=js"
import style0 from "./WorkbookTableNew.vue?vue&type=style&index=0&id=8f5e9cb6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8f5e9cb6",
  null
  
)

export default component.exports