<template>
  <v-card class="w-100">
    <v-card-title class="d-flex align-center justify-space-between">
      <div class="d-flex align-center">
        <!-- TODO: Add solution icon -->
        <div class="ml-3">
          <div class="d-flex align-center">
            <span v-if="trial">
              <span class="hover-field">
                Trial
                <v-icon
                  v-b-tooltip.hover.top="'Copy trial ID'"
                  small
                  class="ml-1"
                  @click.prevent="copyToClipboard(trial.id)"
                >
                  mdi-content-copy
                </v-icon>
              </span>

            </span>
            <span v-else>Subscription</span>
          </div>
          <div style="font-size: smaller; text-align: left">
            Creation date: {{ new Date(trial.created_at).toLocaleDateString() }}<br>
          </div>
          <div
            v-if="trial"
            style="font-size: smaller; text-align: left"
          >
            End date: {{ new Date(trial.end_date).toLocaleDateString() }}<br>
          </div>
          <div v-else>
            End date: {{ new Date(subscription.end_date).toLocaleDateString() }}<br>
          </div>
          <div
            v-if="trial"
            style="font-size: smaller; text-align: left"
          >
            Roles: <span
              v-for="role in role_items"
              :key="role.name"
            >
              {{ role.name }}
            </span>
          </div>
        </div>
      </div>
      <div style="display: flex; align-items: center;" />
      <v-btn
        v-if="!delete_confirmation"
        icon
        class="shadow-none transparent text-dark"
        @click="delete_confirmation = true"
      >
        <v-icon>mdi-delete-outline</v-icon>
      </v-btn>

      <div
        v-if="delete_confirmation"
        class="confirmation-container ml-3"
      >
        <span style="font-size: smaller">Are you sure?</span>

        <v-btn
          text
          class="shadow-none text-danger"
          @click="deleteTrial"
        >
          <v-icon>mdi-delete-outline</v-icon>
          Delete
        </v-btn>

        <v-btn
          text
          class="shadow-none text-dark"
          @click="delete_confirmation = false"
        >
          <v-icon>mdi-close</v-icon>
          Cancel
        </v-btn>
      </div>
    </v-card-title>
  </v-card>
</template>
<script>

import AdminAbstract from "../Mixins/AdminAbstract.vue";
import DataMaLogicAbstract from "@/components/Structure/Mixins/DataMaLogicAbstract.vue";

export default {
  name: 'TrialCard',
  components: {},
  mixins: [AdminAbstract, DataMaLogicAbstract],
  props: {
    subscription: {
      type: Object,
      default: null
    },
    trial: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      delete_confirmation: false,
      deleteTrialBusy: false
    };
  },
  computed: {
    role_items() {
      return this.roles.filter(role => this.trial.roles.includes(role.rid));
    },
  },
  methods: {
    deleteTrial() {
      this.deleteTrialBusy = true
      this.$store.dispatch("admin/deleteTrial", {params: {trial_id: this.trial.id}})
          .then(response => {
            this.$emit('update-trials-list')
            this.registerToast(this.$toast.success(`Trial deleted successfully !`, {timeout: 10000}))
          })
          .catch(error => {
            this.registerToast(this.$toast.error('Failed to delete trial. Please retry.', {timeout: 10000}))
          })
          .finally(() => {
            this.deleteTrialBusy = false
          })
    }
  }
}
</script>

<style scoped lang="scss">
.hover-field .v-icon {
  display: none;
}

.hover-field:hover .v-icon {
  display: inline-block;
}
</style>