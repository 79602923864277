var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.admin)?_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.matrix.headers,"items":_vm.matrix.data},scopedSlots:_vm._u([{key:"body",fn:function(items){return [_c('tbody',_vm._l((items.items),function(item,index_line){return _c('tr',{key:'line-' + index_line},[_c('th',{staticStyle:{"width":"20px","text-align":"center"}},[_vm._v(" "+_vm._s(index_line + 1)+" ")]),_c('th',{staticStyle:{"width":"400px"}},[_c('AceEditor',{staticClass:"border mb-2",attrs:{"id":"issue-message","theme":"chrome","width":"auto","height":"100","lang":"html","options":{
                  autoScrollEditorIntoView: true,
                  enableBasicAutocompletion: true,
                  enableSnippets: true,
                  enableLiveAutocompletion: true,
                  fontSize: 14,
                  lineHeight: 20,
                  highlightActiveLine: false,
                  showLineNumbers: false,
                  tabSize: 2,
                  showPrintMargin: false,
                  showGutter: false,
                  wrap: false,
                }},on:{"blur":function($event){return _vm.update(item)},"init":_vm.init},model:{value:(item.message),callback:function ($$v) {_vm.$set(item, "message", $$v)},expression:"item.message"}})],1),_c('th',[_c('v-checkbox',{staticClass:"m-0 p-0",attrs:{"dense":"","hide-details":"auto"},on:{"change":function($event){return _vm.update(item)}},model:{value:(item.solved),callback:function ($$v) {_vm.$set(item, "solved", $$v)},expression:"item.solved"}})],1),_c('th',[_vm._v(_vm._s(_vm.format(item.created_at)))]),_c('th',[_c('v-btn',{staticClass:"transparent red--text p-0 pl-2 pr-2 shadow-none",on:{"click":function($event){$event.stopPropagation();return _vm.deleteIssue(item.id)}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v(" mdi-delete ")])],1)],1)])}),0)]}}],null,false,1955609161)}),_c('v-divider',{staticClass:"mt-5 mb-5"}),_c('v-card',[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex justify-start w-100"},[_c('div',{staticClass:"w-100 text-left"},[_c('p',[_c('label',{staticClass:"font-weight-bold",attrs:{"for":"issue-message"}},[_vm._v(" Create new issue ")])]),_c('AceEditor',{staticClass:"border mb-2",attrs:{"id":"issue-message","theme":"chrome","width":"auto","height":"300","lang":"html","options":{
                    autoScrollEditorIntoView: true,
                    enableBasicAutocompletion: true,
                    enableSnippets: true,
                    enableLiveAutocompletion: true,
                    fontSize: 14,
                    lineHeight: 20,
                    highlightActiveLine: false,
                    showLineNumbers: false,
                    tabSize: 2,
                    showPrintMargin: false,
                    showGutter: false,
                    wrap: false,
                  }},on:{"init":_vm.init},model:{value:(_vm.edition.message),callback:function ($$v) {_vm.$set(_vm.edition, "message", $$v)},expression:"edition.message"}}),_c('div',{staticClass:"d-flex justify-start"},[_c('v-checkbox',{staticClass:"m-0 p-0",attrs:{"dense":"","hide-details":"auto"},model:{value:(_vm.edition.solved),callback:function ($$v) {_vm.$set(_vm.edition, "solved", $$v)},expression:"edition.solved"}}),_c('label',{staticClass:"font-weight-bold",staticStyle:{"line-height":"24px !important"}},[_vm._v(" Solved ")])],1)],1)]),(_vm.edition.message.length)?_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{staticClass:"transparent red--text p-0 pl-2 pr-2 shadow-none",on:{"click":function($event){$event.stopPropagation();return _vm.create.apply(null, arguments)}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v(" mdi-alert-outline ")]),_vm._v(" Create ")],1)],1):_vm._e()])],1)],1)],1),(_vm.edition.message.length)?_c('v-card',{staticClass:"mt-5"},[_c('v-card-title',[_vm._v("Preview")]),_c('v-card-text',[_c('div',{staticClass:"d-flex justify-start"},[_c('v-icon',{staticClass:"mr-2 float-left",staticStyle:{"color":"#DD4F39 !important"},attrs:{"large":""}},[_vm._v(" mdi-alert-circle-outline ")]),_c('div',{staticClass:"float-left",staticStyle:{"float":"left","font-size":"20px","text-align":"left","margin":"1rem","color":"#DD4F39 !important"},domProps:{"innerHTML":_vm._s(_vm.edition.message)}},[_vm._v(" "+_vm._s(_vm.edition.message)+" ")])],1)])],1):_vm._e()],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }