import { render, staticRenderFns } from "./AddContextPopup.vue?vue&type=template&id=35b3611d&scoped=true"
import script from "./AddContextPopup.vue?vue&type=script&lang=js"
export * from "./AddContextPopup.vue?vue&type=script&lang=js"
import style0 from "./AddContextPopup.vue?vue&type=style&index=0&id=35b3611d&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35b3611d",
  null
  
)

export default component.exports