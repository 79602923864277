var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.current_tab != null && _vm.slide)?_c('v-card',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.clickOutside),expression:"clickOutside"}],key:_vm.key,ref:"container",class:'slide ' + (_vm.loading ? 'loading ' : '') + (_vm.error ? ' error ' : '') + ((_vm.isExported && _vm.exports.selected != null && _vm.exports.channel !== false) ? 'slide-exported' : ''),attrs:{"id":_vm.id,"ripple":false,"data-rendered":_vm.rendered.join(',')},on:{"click":_vm.exportAddSlide}},[(_vm.exports.edition && _vm.exports.configuration.configuration.arrange === 'slides')?_c('div',{staticClass:"export-marker"},[_c('v-icon',[_vm._v("mdi-checkbox-marked-circle-outline")]),_c('v-icon',[_vm._v("mdi-circle-outline")])],1):_vm._e(),(_vm.minimal !== false && _vm.arrange === 'select')?_c('v-select',{attrs:{"items":_vm.select_view_items,"item-text":'name',"item-value":'value'},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}}):_vm._e(),(_vm.has_title)?_c('SlideTitle',{ref:"title",attrs:{"id":_vm.id,"title":Array.isArray(_vm.slide.title) ? _vm.slide.title : [_vm.slide.title],"inputs":_vm.slide.inputs != null ? _vm.slide.inputs : {},"minimal":_vm.minimal,"data-title":_vm.transpileToText(Array.isArray(_vm.slide.title) ? _vm.slide.title : [_vm.slide.title])},on:{"input":(input) => _vm.$emit('input', [input])}}):_vm._e(),(!_vm.minimal || _vm.arrange === 'slides')?_c('v-tabs',{attrs:{"right":"","height":"30"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',{key:"anomaly"},[_vm._v(" Graph ")])],1):_vm._e(),(_vm.actionable || _vm.presentationMode)?_c('SlideAction',{ref:"actions",attrs:{"id":"anomaly-action","docs":_vm.docs[_vm.tab],"downloadable":_vm.excel,"actions":[
      ...(
        _vm.slide.tabs.anomaly.data.Denom_name !== 'NULLdenom' ?
          [
            'detect_show_denom',
            ...(_vm.plotsToDraw[_vm.tab] === 'anomaly' && _vm.isFunnelAnomaly && _vm.isComputeMainKPI ? ['detect_show_main_kpi'] : []),
            'divider',
          ] : []
      ),
      ...(_vm.current_tab.actions || _vm.default_actions)
    ],"plotly":_vm.customizable.plotly,"current":['plotly'][_vm.tab],"boots":_vm.boots},on:{"drop":_vm.resetConfiguration}}):_vm._e(),_c('SlideWarning',{attrs:{"type":_vm.warning_info.type,"message":_vm.warning_info.text,"state":_vm.warning},on:{"dismiss":function($event){_vm.warning = false},"display":function($event){_vm.warning = true}}}),_vm._l((_vm.plotsToDraw),function(element,index){return _c('v-main',{key:'anomaly-tab-' + index,ref:"slide",refInFor:true,class:(!_vm.minimal ? '' : 'exported') + ' ' + (_vm.exports.configuration.configuration.slides[_vm.id] != null && _vm.isCurrentTabExported && _vm.exports.selected != null ? 'no-overlay' : ''),attrs:{"id":_vm.id + '_' + element + _vm.key,"data-exported":"data-image"},nativeOn:{"click":function($event){return _vm.exportAddPlotSelection.apply(null, arguments)}}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.exports.edition && _vm.exports.configuration.configuration.arrange !== 'slides'),expression:"exports.edition && exports.configuration.configuration.arrange !== 'slides'"}],class:'export-marker ' + (_vm.isCurrentTabExported && _vm.exports.selected != null ? 'plot-exported' : '')},[_c('v-icon',[_vm._v("mdi-checkbox-marked-circle-outline")]),_c('v-icon',[_vm._v("mdi-circle-outline")])],1),(element != null)?_c('div',{ref:"chart",refInFor:true}):_vm._e()])}),(_vm.has_comment)?_c('SlideComment',{ref:"comment",attrs:{"id":_vm.id,"comment":_vm.comment,"comment_markdown":_vm.comment_markdown,"minimal":_vm.minimal,"plot":_vm.plotsToDraw[_vm.tab] || _vm.plotsToDraw[0]},on:{"boot":v => _vm.boots.comment = v}}):_vm._e()],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }