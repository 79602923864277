var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isLicenseAdmin)?_c('v-expansion-panels',{attrs:{"value":0,"flat":""}},[_c('v-expansion-panel',{staticClass:"rounded_card"},[_c('v-expansion-panel-header',{attrs:{"expand-icon":"mdi-chevron-right"}},[_c('h5',[_vm._v("License key")])]),_c('v-expansion-panel-content',[_c('div',{staticStyle:{"height":"36px","margin-bottom":"10px"}},[_c('v-btn',{staticClass:"saveProperty mx-auto",staticStyle:{"margin":"0","width":"100%"},on:{"click":function($event){$event.preventDefault();return _vm.generateKey()}}},[_vm._v(" Add license key ")])],1),_vm._l((_vm.filteredLicenses),function(licenseKey,i){return _c('v-card',{key:i,staticClass:"mx-auto",attrs:{"outlined":""}},[_vm._v(" "+_vm._s(licenseKey.organization)+" "),_c('v-list-item',[_c('v-list-item-content',[_c('v-text-field',{attrs:{"disabled":"","type":"password","label":'License key' +
                (licenseKey.is_active === false ? ' (unused)' :
                  ` (in usage) | Last usage : ${_vm.formatDate(licenseKey.updated_at, true)}`) +
                (licenseKey.creator && licenseKey.creator.name && licenseKey.created_by !== licenseKey.uid ?
                  ` | Created by ${licenseKey.creator.name}` : '') +
                (licenseKey.organization && licenseKey.organization.name ?
                  ` | Created in ${licenseKey.organization.name}` : '')},model:{value:(licenseKey.license_key),callback:function ($$v) {_vm.$set(licenseKey, "license_key", $$v)},expression:"licenseKey.license_key"}})],1),_c('v-list-item-action',[_c('div',{staticClass:"d-flex flex-wrap",staticStyle:{"max-width":"100px"}},[_c('v-btn',{staticClass:"saveProperty w-100",attrs:{"right":""},on:{"click":function($event){$event.preventDefault();return _vm.copyToClipboard(licenseKey.license_key)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-content-copy ")]),_vm._v(" Copy ")],1),_c('v-btn',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Revoke & create a new license key'),expression:"'Revoke & create a new license key'",modifiers:{"hover":true,"top":true}}],staticClass:"saveProperty w-100",attrs:{"right":""},on:{"click":function($event){$event.preventDefault();return _vm.update(licenseKey.id)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-refresh ")]),_vm._v(" Update ")],1),_c('v-btn',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Revoke this license key'),expression:"'Revoke this license key'",modifiers:{"hover":true,"top":true}}],staticClass:"saveProperty w-100",attrs:{"right":""},on:{"click":function($event){$event.preventDefault();return _vm.revokeKey(licenseKey.id)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete ")]),_vm._v(" Delete ")],1)],1)])],1)],1)})],2)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }