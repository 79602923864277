import { render, staticRenderFns } from "./SlideSettings.vue?vue&type=template&id=653d8c10"
import script from "./SlideSettings.vue?vue&type=script&lang=js"
export * from "./SlideSettings.vue?vue&type=script&lang=js"
import style0 from "./SlideSettings.vue?vue&type=style&index=0&id=653d8c10&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports